import { Pipe, PipeTransform } from '@angular/core';
import { BundleModel, ProgramDashboardModel } from '@lms/shared/models';
import { getPluralValue } from '@lms/shared/utils';

@Pipe({
  name: 'buildBundleProgressInfo',
  standalone: false,
})
export class BuildBundleProgressInfoPipe implements PipeTransform {
  public transform(bundle: BundleModel | ProgramDashboardModel): string {
    let progress =
      bundle.totalCoreCourses > 0
        ? bundle.totalCompletedCoreCourses +
          '/' +
          bundle.totalCoreCourses +
          ' ' +
          getPluralValue('Core Course', bundle.totalCompletedCoreCourses || 0) +
          ' Completed'
        : '';

    if (bundle.minElectiveCourses > 0 && bundle.totalElectiveCourses > 0) {
      const totalElectiveCourses =
        bundle.totalElectiveCourses < bundle.minElectiveCourses ? bundle.totalElectiveCourses : bundle.minElectiveCourses;
      progress += progress ? ' | ' : '';
      progress +=
        bundle.totalCompletedElectiveCourses +
        '/' +
        totalElectiveCourses +
        ' ' +
        getPluralValue('Elective Course', bundle.totalCompletedElectiveCourses || 0) +
        ' Completed';
    }

    return progress;
  }
}
