import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AdminPanelDomainPipe,
  ApiDomainPipe,
  BytesToMegaBytesPipe,
  DashboardDomainPipe,
  DictionaryPipe,
  DurationPipe,
  FirstLetterPipe,
  FormatDateDescriptivePipe,
  FormatDatePipe,
  FormatDatetimePipe,
  FormatLessonContentTypePipe,
  FormatTimePipe,
  IdToNamePipe,
  IdToProgramPipe,
  PluralPipe,
  SafeHtmlPipe,
  StripDotPipe,
  TrademarkPipe,
  TranslateEnumPipe,
  TruncatePipe,
  UniquePipe,
  UserFullNamePipe,
  EmptyIfTooLongPipe,
} from './lib';

import { PricingPlanPricePipe } from './lib/pricing-plan-price.pipe';
import { FileTypeIconPipe } from './lib/file-type-icon.pipe';
import { TimeDiffPipe } from './lib/time-diff.pipe';
import { BuildUserDetailsDropdownLabelPipe } from './lib/build-user-details-dropdown-label.pipe';
import { PricePipe } from './lib/price.pipe';
import { PricingPlanTaxedPricePipe } from './lib/pricing-plan-taxed-price.pipe';
import { TaxNamePipe } from './lib/tax-name.pipe';
import { PricingPlanDiscountedPricePipe } from './lib/pricing-plan-discounted-price.pipe';
import { PricingPlanFirstPaymentPricePipe } from './lib/pricing-plan-first-payment-price.pipe';
import { RemoveHostPipe } from './lib/remove-host.pipe';
import { HighlightSearchPipe } from './lib/highlight-search.pipe';
import { SecondsToTimeLength } from './lib/seconds-to-time-length';
import { BuildBundleProgressInfoPipe } from './lib/build-bundle-progress-info.pipe';

const PIPES = [
  AdminPanelDomainPipe,
  ApiDomainPipe,
  BuildBundleProgressInfoPipe,
  BytesToMegaBytesPipe,
  DashboardDomainPipe,
  DictionaryPipe,
  DurationPipe,
  FormatDatePipe,
  FormatDateDescriptivePipe,
  FormatDatetimePipe,
  FormatTimePipe,
  FormatLessonContentTypePipe,
  FileTypeIconPipe,
  HighlightSearchPipe,
  TruncatePipe,
  FirstLetterPipe,
  UserFullNamePipe,
  PluralPipe,
  PricePipe,
  PricingPlanPricePipe,
  PricingPlanTaxedPricePipe,
  PricingPlanDiscountedPricePipe,
  PricingPlanFirstPaymentPricePipe,
  SafeHtmlPipe,
  StripDotPipe,
  TaxNamePipe,
  TimeDiffPipe,
  TrademarkPipe,
  TranslateEnumPipe,
  BuildUserDetailsDropdownLabelPipe,
  IdToProgramPipe,
  RemoveHostPipe,
  UniquePipe,
  IdToNamePipe,
  SecondsToTimeLength,
  EmptyIfTooLongPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: PIPES,
  exports: PIPES,
  providers: [PricePipe, TrademarkPipe],
})
export class SharedPipesModule {}
