@if (status !== BundleEnrollmentStatus.COMPLETED && status !== BundleEnrollmentStatus.PASSED && status !== BundleEnrollmentStatus.FAILED) {
  <div
    [hidden]="!(percentAtBottom || percentAtRight)"
    [ngClass]="{
      'hide-complete': hideComplete,
      'progress-short-view-right': percentAtRight,
    }"
  >
    <div class="progress_bar">
      <span [style.width.%]="progress"></span>
    </div>
    <div class="progress_label">{{ progress }}% <span>complete</span></div>
  </div>

  <div
    [hidden]="percentAtBottom || percentAtRight"
    [ngClass]="{
      'completed-view': setCompletedView,
      'grey-view': setGreyView,
      'progress-short-view': !percentAtBottom,
    }"
  >
    <div class="visible-tablet progress_info">{{ progressInfo }}</div>
    <div class="progress_top">
      <div class="hidden-tablet progress_info">{{ progressInfo }}</div>
      <div class="progress_label">
        @if (!showTooltip) {
          {{ progress }}%
        } @else {
          @if (progress) {
            <app-tooltip position="{{ isMobile ? 'right' : 'top' }}" [isCentered]="true" [notIcon]="true" [size]="'middle'">
              <ng-template #code> {{ progress }}% </ng-template>
              You have completed courses included in this program.
            </app-tooltip>
          } @else {
            {{ progress }}%
          }
        }
      </div>
    </div>
    <div class="progress_bar">
      <span [style.width.%]="progress"></span>
    </div>
  </div>
}
