<div class="card_box">
  <button aria-label="close" class="close-btn" mat-dialog-close>
    <app-svg-icon class="ico_close" icon="close"></app-svg-icon>
    Close
  </button>

  <div class="card_box_title">
    <app-svg-icon icon="course_locked"></app-svg-icon>
    <h3>Upgrade to Unlock AI Resume Creator</h3>
  </div>

  <div class="items row">
    <div class="item col-12">
      <p class="card_box_text">
        Transform your job search with CFI's AI Resume Creator, exclusively available for Full-Immersion learners.
      </p>
      <p class="card_box_text">
        Craft resumes, cover letters, and LinkedIn profiles that set you apart and fast-track your career success.
      </p>
      <p class="card_box_text">Upgrade now to get access.</p>
    </div>
    <div class="item col-12">
      <div class="buttons">
        <a [href]="'https://corporatefinanceinstitute.com/upgrade/'" class="button gold" target="_blank" mat-dialog-close>
          <span class="wrapping-content">
            <span class="before">Upgrade Now</span>
            <span class="after">Upgrade Now</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
