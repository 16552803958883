import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { BundleEnrollmentStatusEnum } from '@lms/shared/models';
import { CustomBreakpointNames, LayoutService } from '@lms/shared/services/app-services';
import { takeUntil } from 'rxjs';
import { DestroyableDirective } from '../destroyable/destroyable.directive';

@Component({
  selector: 'app-course-progress',
  templateUrl: './course-progress.component.html',
  styleUrls: ['./course-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CourseProgressComponent extends DestroyableDirective implements OnInit {
  @Input() progress: number | string | undefined | null = 0;
  @Input() status: BundleEnrollmentStatusEnum | undefined | null;
  @Input() percentAtBottom: boolean = false;
  @Input() percentAtRight: boolean = false;
  @Input() hideComplete: boolean = false;
  @Input() progressInfo: string = '';
  @Input() showTooltip: boolean = false;
  @Input() setGreyView: boolean = false;
  @Input() setCompletedView: boolean = false;

  BundleEnrollmentStatus = BundleEnrollmentStatusEnum;

  isMobile = false;

  constructor(
    private layoutService: LayoutService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutService
      .subscribeToLayoutChanges()
      .pipe(takeUntil(this._destroy$$))
      .subscribe(data => {
        this.isMobile = data.includes(CustomBreakpointNames.phone);
        this.cdr.markForCheck();
      });
  }
}
